import { Navigate } from "react-router-dom";
import { createContext, useState, useContext } from "react";
import { useCookies } from "react-cookie";
import { set, useForm, Controller } from "react-hook-form";
import { AuthContext } from "./App";
import "./App.css";
import { auth } from "./services/api";

function Login() {
  const { isAuthenticated, setIsAuthenticated, setUser } =
    useContext(AuthContext);
  const [cookies, setCookie] = useCookies(["auth"]);
  const {
    register,
    handleSubmit,
    control,
    watch,
    unregister,
    reset,
    formState: { errors },
  } = useForm();
  const [error, setError] = useState(null);

  if (isAuthenticated) return <Navigate to="/" />;
  console.log("login");

  const handleLogin = (data) => {
    const username = data.username;
    const password = data.password;
    auth(username, password).then((res) => {
      if (res != undefined) {
        setCookie("auth", "true", { path: "/" });
        setIsAuthenticated(true);
        setUser(res.data);
        setError(null);
      } else {
        setError("Invalid username or password");
      }
    });
  };

  return (
    <div className="loginContainer">
      <div className="formContainer">
        <form onSubmit={handleSubmit(handleLogin)}>
          <ul>
            <li>
              <input
                type="text"
                placeholder="Username"
                {...register("username")}
                required
              />
            </li>
            <li>
              <input
                type="password"
                placeholder="Password"
                {...register("password")}
                required
              />
            </li>
            <li>
              {error && <p className="error">{error}</p>}
              <button className="booking-button">Login</button>
            </li>
          </ul>
        </form>
      </div>
    </div>
  );
}

export default Login;
