import Sidenav from "./components/Sidenav/Sidenav";
import BookingForm from "./components/BookingForm/BookingForm";

import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./App";
import { useNavigate, useLocation } from "react-router-dom";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import "./App.css";

import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import DataTable from "react-data-table-component";

import { getBookings } from "./services/api";

function Dashboard() {
  const {
    isSidenavActive,
    setIsSidenavActive,
    bookingActive,
    setBookingActive,
  } = useContext(AuthContext);
  var sidenavActiveClass = !isSidenavActive ? "menu-active" : "menu-inactive";
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 7));
  const [state, setState] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    },
  ]);
  const [data, setData] = useState([]);
  const [calendarActive, setCalendarActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchText, setSearchText] = useState("");

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  var calendarActiveClass = calendarActive
    ? "calendar-active"
    : "calendar-inactive";

  function handleClick() {
    setCalendarActive(!calendarActive);
  }

  function handleBookingClick() {
    setBookingActive(!bookingActive);
    setCalendarActive(false);
  }

  function onDateChange(date) {
    setState([date.selection]);
    if (
      date.selection.startDate.toString() != date.selection.endDate.toString()
    ) {
      setCalendarActive(!calendarActive);
      setStartDate(date.selection.startDate);
      setEndDate(date.selection.endDate);
    }
  }

  const columns = [
    {
      name: "REF",
      selector: (row) => row.booking_id,
      sortable: true,
      width: "80px",
    },
    {
      name: "Date",
      selector: (row) => {
        return row.booking_date + " " + row.booking_time;
      },
      sortable: true,
    },
    {
      name: "PAX Name",
      selector: (row) => row.pax_name,
      sortable: true,
    },
    {
      name: "Start",
      selector: (row) => row.start,
      sortable: true,
      hideOnMobile: true,
      style: { whiteSpace: "unset" },
    },
    {
      name: "End",
      selector: (row) => row.end,
      sortable: true,
      hideOnMobile: true,
      style: { whiteSpace: "unset" },
    },
    {
      name: "PAX",
      selector: (row) => row.total_passengers,
      sortable: true,
      width: "80px",
    },
    {
      name: "Flight",
      selector: (row) => row.flight_number,
      sortable: true,
      width: "90px",
      hideOnMobile: true,
    },
    {
      name: "Status",
      selector: (row) => row.booking_status,
      sortable: true,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      let res = await getBookings(
        startDate.toLocaleDateString(),
        endDate.toLocaleDateString()
      );
      setData(res);
      console.log(res);
    };
    fetchData();
  }, [state]);

  const handleRowClicked = (row) => {
    console.log(row);
    navigate(`/booking/${row.booking_id}`, {
      state: { from: location.pathname },
    });
  };

  const filteredData = data.filter((item) =>
    Object.keys(item).some(
      (key) =>
        item[key].toString().toLowerCase().indexOf(searchText.toLowerCase()) >
        -1
    )
  );

  return (
    <main>
      <Sidenav></Sidenav>
      <div className="content">
        <div className="header">
          <div>
            <button
              id="menutoggle"
              style={{
                marginRight: 20,
              }}
              className={sidenavActiveClass}
              onClick={() => {
                setIsSidenavActive(!isSidenavActive);
              }}
            >
              <span className="material-symbols-outlined icon-profile ">
                menu
              </span>
            </button>
            <button id="dateSelector" onClick={handleClick}>
              {startDate.toDateString()} - {endDate.toDateString()}
            </button>
            <div
              id="dateContainer"
              className={[calendarActiveClass, sidenavActiveClass].join(" ")}
            >
              <DateRange
                data-tag="allowRowEvents"
                onChange={(item) => onDateChange(item)}
                moveRangeOnFirstSelection={false}
                ranges={state}
                noBorder={true}
                showDateDisplay={false}
                rangeColors={["#000"]}
              />
            </div>
            <input
              type="text"
              value={searchText}
              onChange={handleSearch}
              placeholder="Search"
              style={{
                marginLeft: 20,
                width: 300,
              }}
            />
          </div>
          <button id="newBookingButton" onClick={handleBookingClick}>
            Booking
          </button>
        </div>
        <div
          className="bookingtable"
          style={{
            width: window.width,
          }}
        >
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover={true}
            striped={true}
            pointerOnHover={true}
            onRowClicked={handleRowClicked}
            direction="auto"
            fixedHeader
            fixedHeaderScrollHeight="530px"
            persistTableHead
            responsive
          />
        </div>
      </div>
      <div>{bookingActive ? <BookingForm /> : ""}</div>
    </main>
  );
}

export default Dashboard;
