import { useState, useContext, useEffect } from "react";
import DataTable from "react-data-table-component";
import { getPaymentTypes } from "../../services/api";
import { AuthContext } from "../../App";

function PaymentType() {
  const {
    settingsFormActive,
    setSettingsFormActive,
    setEvent,
    setSettingsForm,
    settingsForm,
  } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const columns = [
    {
      name: "Name:",
      selector: (row) => row.payment_type,
      sortable: true,
    },
  ];

  useEffect(() => {
    getPaymentTypes().then(async (res) => {
      setData(res);
      console.log(res);
    });
  }, []);

  const handleRowClicked = (event) => {
    setSettingsForm("payment");
    setEvent(event);
    setSettingsFormActive(true);
  };

  const handleAddClick = () => {
    setSettingsForm("payment");
    setEvent(null);
    setSettingsFormActive(true);
  };

  const handleSearch = (event) => {
    console.log(event.target.value);
    setSearchText(event.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.keys(item).some(
      (key) =>
        item[key] !== null &&
        item[key].toString().toLowerCase().indexOf(searchText.toLowerCase()) >
          -1
    )
  );
  return (
    <div>
      <div className="settings-component-header">
        <input
          type="text"
          value={searchText}
          onChange={handleSearch}
          placeholder="Search"
          style={{
            marginLeft: 20,
            width: 300,
          }}
        />
        <button className="booking-button" onClick={handleAddClick}>
          add
        </button>
      </div>
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        highlightOnHover={true}
        striped={true}
        pointerOnHover={true}
        onRowClicked={(event) => handleRowClicked(event)}
        direction="auto"
        fixedHeader
        fixedHeaderScrollHeight="530px"
        persistTableHead
        responsive
        dense
      />
    </div>
  );
}

export default PaymentType;
