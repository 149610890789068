import "./BookingForm.css";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../App";
import { set, useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useState } from "react";
import {
  getClients,
  getServices,
  getPrices,
  getLocations,
  getVehicles,
  getPaymentTypes,
} from "../../services/api";

import CurrencyInput from "react-currency-input-field";

function BookingForm(props) {
  const { setBookingActive } = useContext(AuthContext);
  function handleClick() {
    setBookingActive(false);
  }
  const {
    register,
    handleSubmit,
    control,
    watch,
    unregister,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { vehicles: [] } });
  const onSubmit = (data) => console.log(data);
  const [clientOptions, setClientOptions] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [prices, setPrices] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [vehicleOtions, setVehicleOptions] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [tripComponents, setTripComonents] = useState([]);
  const [editBooking, setEditBooking] = useState(props.data);

  useEffect(() => {
    console.log("use effect");
    getClients().then(async (res) => {
      setClientOptions(
        res.map((client) => ({
          value: client.id,
          label: client.organisation_name,
        }))
      );
    });

    getServices().then(async (res) => {
      setServiceOptions(
        res.map((service) => ({
          value: service.id,
          label: service.service_name,
        }))
      );
    });

    getPrices().then(async (res) => {
      setPrices(res);
    });

    getLocations().then(async (res) => {
      setLocationOptions(
        res.map((location) => ({
          value: location.id,
          label: location.location_name,
        }))
      );
    });

    getVehicles().then(async (res) => {
      setVehicleOptions(
        res.map((vehicle) => ({
          value: vehicle.id,
          label: vehicle.vehicle_name,
        }))
      );
    });

    getPaymentTypes().then(async (res) => {
      setPaymentOptions(
        res.map((payment) => ({
          value: payment.id,
          label: payment.payment_type,
        }))
      );
    });
    console.log(editBooking);
    if (editBooking != undefined) {
      reset({
        paxname: editBooking.pax_name,
        pax: editBooking.total_passengers,
        flight: editBooking.flight_number,
        comment: editBooking.internal_comments,
        extra: editBooking.comments,
        price: parseFloat(editBooking.price),
      });

      console.log(`${editBooking.booking_date}T${editBooking.booking_time}`);
    }
  }, []);

  const handleAddVehicleClick = (event) => {
    event.preventDefault();
    const index = tripComponents.length;
    setTripComonents([
      ...tripComponents,
      <AddVehicleComponent index={index} />,
    ]);
  };

  const handleRemoveVehicleClick = (index, event) => {
    event.preventDefault();
    const newComponents = [...tripComponents];
    newComponents.splice(index, 1);
    setTripComonents(newComponents);
    unregister(`vehicles[${index}]`);
  };

  function AddVehicleComponent(props) {
    const index = props.index;
    const controller_name = `vehicles[${index}]`;
    return (
      <div>
        <Controller
          name={controller_name}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select {...field} options={vehicleOtions} placeholder="Vehicle" />
          )}
        />
      </div>
    );
  }

  const convertDateTime = (date, time) => {
    const [hour, minute, period] = time.split(/:|(?<=\d)(?=[ap]m)/i);
    if (hour < 10) {
      const paddedHour = hour.padStart(2, "0");
      time = `${paddedHour}:${minute}:${period}`;
    }
    console.log(`${date}T${time}`);
    return `${date}T${time}`;
  };

  return (
    <div>
      <div className="booking-container" onClick={handleClick}></div>
      <div className="booking-form-container">
        <div className="booking-form-header">
          {editBooking != undefined ? (
            <h1>#{props.data.booking_id}</h1>
          ) : (
            <h1>Requset</h1>
          )}
          <button className="booking-form-close-button" onClick={handleClick}>
            {" "}
            <span className="material-symbols-outlined icon-profile">
              close
            </span>
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ul>
            <li>
              <p className="select-label">Client</p>
              <Controller
                name="client"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={clientOptions}
                    placeholder="Client"
                    defaultValue={{
                      label:
                        editBooking != undefined
                          ? editBooking.organisation_name
                          : null,
                    }}
                  />
                )}
              />
            </li>
            <li>
              <input
                type="text"
                placeholder="PAX Name"
                required
                {...register("paxname")}
              />
            </li>
            <li>
              <input
                type="number"
                placeholder="PAX"
                required
                {...register("pax")}
              />
            </li>
            <li>
              <p className="select-label">Date</p>
              <input
                type="datetime-local"
                required
                {...register("datetime")}
                defaultValue={
                  editBooking != undefined
                    ? convertDateTime(
                        editBooking.booking_date,
                        editBooking.booking_time
                      )
                    : ""
                }
              />
            </li>
            <li>
              <p className="select-label">Service</p>
              <Controller
                name="service"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={serviceOptions}
                    placeholder="Service"
                    defaultValue={{
                      label:
                        editBooking != undefined
                          ? editBooking.service_name
                          : "",
                    }}
                  />
                )}
              />
            </li>
            <li>
              <p className="select-label">From</p>
              <Controller
                name="from"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={locationOptions}
                    placeholder="From"
                    defaultValue={{
                      label: editBooking != undefined ? editBooking.start : "",
                    }}
                  />
                )}
              />
            </li>
            <li>
              <p className="select-label">To</p>
              <Controller
                name="to"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={locationOptions}
                    placeholder="To"
                    defaultValue={{
                      label: editBooking != undefined ? editBooking.end : "",
                    }}
                  />
                )}
              />
            </li>
            <li>
              <p className="select-label">Payment Method</p>
              <Controller
                name="payment"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={paymentOptions}
                    placeholder="Payment Method"
                    defaultValue={{
                      label:
                        editBooking != undefined
                          ? editBooking.payment_type
                          : "",
                    }}
                  />
                )}
              />
            </li>
            <li>
              <div className="vehicle-header">
                <h3>Vehicle</h3>
                <button
                  className="booking-button"
                  onClick={handleAddVehicleClick}
                >
                  add
                </button>
              </div>
              {tripComponents.map((tripComponents, index) => (
                <div key={index}>
                  {tripComponents}
                  <button
                    className="remove-vehicle-button"
                    onClick={(event) => handleRemoveVehicleClick(index, event)}
                  >
                    Remove
                  </button>
                </div>
              ))}
            </li>
            <li>
              <input type="text" placeholder="Flight" {...register("flight")} />
            </li>
            <li>
              <textarea placeholder="Extra" {...register("extra")}></textarea>
            </li>
            <li>
              <textarea
                placeholder="Comment"
                {...register("comment")}
              ></textarea>
            </li>
            <li>
              <CurrencyInput
                prefix="R"
                placeholder="Price"
                decimalsLimit={2}
                onValueChange={(value) => console.log(value)}
                {...register("price")}
                defaultValue={
                  editBooking != undefined ? parseFloat(editBooking.price) : 0.0
                }
              />
            </li>
            <li>
              <CurrencyInput
                prefix="R"
                placeholder="Discount"
                decimalsLimit={2}
                onValueChange={(value) => console.log(value)}
                {...register("discount")}
                defaultValue={
                  editBooking != undefined
                    ? parseFloat(editBooking.discount)
                    : 0.0
                }
              />
            </li>
          </ul>
          <div className="booking-form-footer">
            <button className="booking-button">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}
export default BookingForm;
