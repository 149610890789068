import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Sidenav from "./components/Sidenav/Sidenav";
import { AuthContext } from "./App";
import { getBooking } from "./services/api";
import BookingForm from "./components/BookingForm/BookingForm";

function Booking() {
  // future > check if user is autherised to view booking id
  const { id } = useParams();
  const {
    isSidenavActive,
    setIsSidenavActive,
    bookingActive,
    setBookingActive,
  } = useContext(AuthContext);
  var sidenavActiveClass = !isSidenavActive ? "menu-active" : "menu-inactive";
  const navigate = useNavigate();
  const [booking, setBooking] = useState({});

  const handleBackClick = () => {
    navigate("/");
  };

  useEffect(() => {
    getBooking(id).then((data) => {
      console.log(data);
      if (data.length === 1) {
        setBooking(data[0]);
      }
    });
  }, []);

  const handleEditBookingClick = () => {
    console.log("edit booking");
    setBookingActive(true);
  };

  return (
    <main>
      <Sidenav></Sidenav>
      <div className="content">
        <div className="header">
          <div className="header-left">
            <button
              id="menutoggle"
              style={{
                marginRight: 20,
              }}
              className={sidenavActiveClass}
              onClick={() => {
                setIsSidenavActive(!isSidenavActive);
              }}
            >
              <span className="material-symbols-outlined icon-profile ">
                menu
              </span>
            </button>
            <div className="booking-title">
              <div>
                <h2>#{booking.booking_id}</h2>
                <h3>{booking.pax_name} </h3>
              </div>
              <button
                style={{
                  background: "none",
                  border: "none",
                  marginTop: 20,
                }}
                onClick={handleEditBookingClick}
              >
                <span className="material-symbols-outlined">edit</span>
              </button>
            </div>
          </div>
          <button className="booking-button" onClick={handleBackClick}>
            back
          </button>
        </div>
        <div className="booking-content">
          <div className="booking-card-row">
            <div
              className="booking-card-item"
              style={{
                background: "#F0ECFD",
              }}
            >
              <div
                className="booking-card-item-icon-container"
                style={{ borderColor: "#5D5FEF" }}
              >
                <span
                  className="material-symbols-outlined icon-profile"
                  style={{
                    color: "#5D5FEF",
                  }}
                >
                  schedule
                </span>
              </div>
              <p style={{ color: "#5D5FEF" }}>
                {booking.booking_date} {booking.booking_time}
              </p>
            </div>
            <div
              className="booking-card-item"
              style={{
                background: "#E0EFFF",
              }}
            >
              <div
                className="booking-card-item-icon-container"
                style={{ borderColor: "#007CFF" }}
              >
                <span
                  className="material-symbols-outlined"
                  style={{ color: "#007CFF" }}
                >
                  account_circle
                </span>
              </div>
              <p style={{ color: "#007CFF" }}>{booking.organisation_name}</p>
            </div>
            <div
              className="booking-card-item"
              style={{
                background: "#FFFAE0",
              }}
            >
              <div
                className="booking-card-item-icon-container"
                style={{ borderColor: "#FFC700" }}
              >
                <span
                  className="material-symbols-outlined"
                  style={{
                    color: "#FFC700",
                  }}
                >
                  room_service
                </span>
              </div>
              <p
                style={{
                  color: "#FFC700",
                }}
              >
                {booking.service_name}
              </p>
            </div>
            <div
              className="booking-card-item"
              style={{
                background: "#F7E0FF",
              }}
            >
              <div
                className="booking-card-item-icon-container"
                style={{ borderColor: "#BD00FF" }}
              >
                <span
                  className="material-symbols-outlined"
                  style={{ color: "#BD00FF" }}
                >
                  hourglass_bottom
                </span>
              </div>
              <p style={{ color: "#BD00FF" }}>{booking.booking_status}</p>
            </div>
          </div>
          <div className="booking-information-header">
            <h2>Booking Information</h2>
            <div>
              <button className="booking-button">Name board</button>
              <button
                className="booking-button"
                style={{
                  marginLeft: 20,
                }}
              >
                Booking confirmation
              </button>
            </div>
          </div>
          <div className="locations">
            <p>
              <b>From:</b> {booking.start}
            </p>
            <p>
              <b>To:</b> {booking.end}
            </p>
          </div>
          <div className="pax">
            <p>
              <b>Flight:</b> {booking.flight_number}
            </p>
            <p>
              <b>PAX:</b> {booking.total_passengers}
            </p>
          </div>
        </div>
        <div className="comments">
          <b>Extra:</b>
          <p>{booking.comments}</p>
        </div>
        <div className="comments">
          <b>Comment:</b>
          <p>{booking.internal_comments}</p>
        </div>
        <div className="price">
          <p>
            <b>Price:</b> R {booking.price}
          </p>
          <p>
            <b>Discount:</b> R {booking.discount}
          </p>
        </div>
      </div>
      <div>{bookingActive ? <BookingForm data={booking} /> : ""}</div>
    </main>
  );
}

export default Booking;
