import "./App.css";
import Sidenav from "./components/Sidenav/Sidenav";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "./App";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import DataTable from "react-data-table-component";
import {
  getClients,
  getVehicles,
  getServices,
  getLocations,
  getPrices,
  getPaymentTypes,
  getUsers,
} from "./services/api";
import SettingsForm from "./components/SettingsForm/SettingsForm";
import Clients from "./components/Settings/Clients";
import Users from "./components/Settings/Users";
import Vehicles from "./components/Settings/Vehicles";
import Services from "./components/Settings/Services";
import Locations from "./components/Settings/Locations";
import Prices from "./components/Settings/Prices";
import PaymentType from "./components/Settings/Payments";

function Settings() {
  const { isSidenavActive, setIsSidenavActive } = useContext(AuthContext);
  const { settingsFormActive, setSettingsFormActive } = useContext(AuthContext);
  const { settingsForm, setSettingsForm } = useContext(AuthContext);
  const { event, setEvent } = useContext(AuthContext);

  var sidenavActiveClass = !isSidenavActive ? "menu-active" : "menu-inactive";

  useEffect(() => {
    console.log("trigger useEffect");
  }, []);

  return (
    <main>
      <Sidenav></Sidenav>
      <div className="content">
        <div className="header">
          <div className="header-left">
            <button
              id="menutoggle"
              style={{
                marginRight: 20,
              }}
              className={sidenavActiveClass}
              onClick={() => {
                setIsSidenavActive(false);
              }}
            >
              <span className="material-symbols-outlined icon-profile ">
                menu
              </span>
            </button>
            <div className="booking-title">
              <div>
                <h1>Settings</h1>
              </div>
            </div>
          </div>
        </div>
        <Tabs>
          <TabList>
            <Tab>Users</Tab>
            <Tab>Clients</Tab>
            <Tab>Vehicles</Tab>
            <Tab>Services</Tab>
            <Tab>Locations</Tab>
            <Tab>Prices</Tab>
            <Tab>Payment Methods</Tab>
          </TabList>

          <TabPanel>
            <Users />
          </TabPanel>
          <TabPanel>
            <Clients />
          </TabPanel>
          <TabPanel>
            <Vehicles />
          </TabPanel>
          <TabPanel>
            <Services />
          </TabPanel>
          <TabPanel>
            <Locations />
          </TabPanel>
          <TabPanel>
            <Prices />
          </TabPanel>
          <TabPanel>
            <PaymentType />
          </TabPanel>
        </Tabs>
      </div>
      <div>
        {settingsFormActive ? (
          <SettingsForm form={settingsForm} event={event} />
        ) : (
          <></>
        )}
      </div>
    </main>
  );
}

export default Settings;
