import "./SettingsForm.css";
import { AuthContext } from "../../App";
import { useContext, useState } from "react";
import Select from "react-select";
import { set, useForm, Controller } from "react-hook-form";

function SettingsForm(props) {
  const { settingsFormActive, setSettingsFormActive } = useContext(AuthContext);
  console.log(props);
  const {
    register,
    handleSubmit,
    control,
    watch,
    unregister,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { vehicles: [] } });

  function handleClick() {
    setSettingsFormActive(false);
  }

  const ClientForm = (props) => {
    const [data, setData] = useState(props.event);
    const {
      register,
      handleSubmit,
      control,
      reset,
      formState: { errors },
    } = useForm({ defaultValues: { data } });
    const onSubmit = (data) => {
      console.log(data);
      setSettingsFormActive(false);
    };

    return (
      <div>
        <h2>Client</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ul>
            <li>
              <input
                className=""
                type="text"
                placeholder="Name"
                defaultValue={data != null ? data.organisation_name : null}
                {...register("name")}
                required
              />
            </li>
          </ul>
          <button className="booking-button">Save</button>
        </form>
      </div>
    );
  };

  const VehicleForm = (props) => {
    const [data, setData] = useState(props.event);
    const {
      register,
      handleSubmit,
      control,
      reset,
      formState: { errors },
    } = useForm({ defaultValues: { data } });
    const onSubmit = (data) => {
      console.log(data);
      setSettingsFormActive(false);
    };
    return (
      <div>
        <h2>Vehicle</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ul>
            <li>
              <input
                className=""
                type="text"
                placeholder="Name"
                defaultValue={data != null ? data.vehicle_name : null}
                {...register("name")}
                required
              />
            </li>
          </ul>
          <button className="booking-button">Save</button>
        </form>
      </div>
    );
  };

  const ServiceForm = (props) => {
    const [data, setData] = useState(props.event);
    const {
      register,
      handleSubmit,
      control,
      reset,
      formState: { errors },
    } = useForm({ defaultValues: { data } });

    const onSubmit = (data) => {
      console.log(data);
      setSettingsFormActive(false);
    };
    return (
      <div>
        <h2>Service</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ul>
            <li>
              <input
                className=""
                type="text"
                placeholder="Name"
                defaultValue={data != null ? data.service_name : null}
                {...register("name")}
                required
              />
            </li>
          </ul>
          <button className="booking-button">Save</button>
        </form>
      </div>
    );
  };

  const LocationForm = () => {
    const [data, setData] = useState(props.event);
    const {
      register,
      handleSubmit,
      control,
      reset,
      formState: { errors },
    } = useForm({ defaultValues: { data } });
    const onSubmit = (data) => {
      console.log(data);
      setSettingsFormActive(false);
    };
    return (
      <div>
        <h2>Location</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ul>
            <li>
              <input
                className=""
                type="text"
                placeholder="Name"
                defaultValue={data != null ? data.location_name : null}
                {...register("name")}
                required
              />
            </li>
          </ul>
          <button className="booking-button">Save</button>
        </form>
      </div>
    );
  };
  const PaymentForm = (props) => {
    const [data, setData] = useState(props.event);
    const {
      register,
      handleSubmit,
      control,
      reset,
      formState: { errors },
    } = useForm({ defaultValues: { data } });
    const onSubmit = (data) => {
      console.log(data);
      setSettingsFormActive(false);
    };
    return (
      <div>
        <h2>Payment</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ul>
            <li>
              <input
                className=""
                type="text"
                placeholder="Name"
                defaultValue={data != null ? data.payment_type : null}
                {...register("name")}
                required
              />
            </li>
          </ul>
          <button className="booking-button">Save</button>
        </form>
      </div>
    );
  };
  const PriceForm = () => {
    return <div>price form</div>;
  };
  const UserForm = () => {
    return <div>users form</div>;
  };

  return (
    <div>
      <div className="booking-container" onClick={handleClick}></div>
      <div className="booking-form-container">
        {props.form === "user" ? <UserForm event={props.event} /> : <></>}
        {props.form === "client" ? <ClientForm event={props.event} /> : <></>}
        {props.form === "vehicle" ? <VehicleForm event={props.event} /> : <></>}
        {props.form === "service" ? <ServiceForm event={props.event} /> : <></>}
        {props.form === "location" ? (
          <LocationForm event={props.event} />
        ) : (
          <></>
        )}
        {props.form === "price" ? <PriceForm event={props.event} /> : <></>}
        {props.form === "payment" ? <PaymentForm event={props.event} /> : <></>}
      </div>
    </div>
  );
}

export default SettingsForm;
