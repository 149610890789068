import { useContext, Link } from "react";
import "./Sidenav.css";
import { AuthContext } from "../../App";
import { BrowserRouter, Match, Miss, NavLink } from "react-router-dom";
import { useCookies } from "react-cookie";

function Sidenav() {
  const { isSidenavActive, setIsSidenavActive, setIsAuthenticated, user } =
    useContext(AuthContext);
  const [cookies, removeCookie] = useCookies(["auth"]);

  var active = isSidenavActive ? "active" : "";

  const handleLogout = () => {
    setIsAuthenticated(false);
    removeCookie("auth", { path: "/" }, { expires: new Date(0) });
  };

  return (
    <div id="sidenav" className={active}>
      <button
        id="menutoggle"
        className={active}
        onClick={() => {
          setIsSidenavActive(!isSidenavActive);
        }}
      >
        <span className="material-symbols-outlined icon-profile">close</span>
      </button>
      <div className="userinfo">
        <div className="avatar"></div>
        <div className="user">
          <h3>Hello</h3>
          <h2>{user ? user.firstname : <></>} </h2>
        </div>
      </div>
      <div>
        <ul>
          <li>
            <NavLink exact activeClassName="is-active" to="/">
              Bookings
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="is-active" to="/reports">
              Reports
            </NavLink>
          </li>
        </ul>
        <hr />
        <ul>
          <li>
            <NavLink activeClassName="is-active" to="/settings">
              Settings
            </NavLink>
          </li>
          <li>
            <NavLink to="/login" onClick={handleLogout}>
              Logout
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidenav;
