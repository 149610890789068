import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { createContext, useState, useContext } from "react";
import { useCookies } from "react-cookie";
import Dashboard from "./Dashboard";
import Login from "./Login";
import Booking from "./Booking";
import NotFound from "./NotFound";
import Settings from "./Settings";
import { set } from "react-hook-form";

export const AuthContext = createContext();

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cookies] = useCookies(["auth"]);
  const [user, setUser] = useState(null);
  const [isSidenavActive, setIsSidenavActive] = useState(false);
  const [bookingActive, setBookingActive] = useState(false);
  const [settingsFormActive, setSettingsFormActive] = useState(false);
  const [settingsForm, setSettingsForm] = useState(null);
  const [event, setEvent] = useState(null);

  useEffect(() => {
    if (cookies.auth) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [settingsForm]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        isSidenavActive,
        setIsSidenavActive,
        bookingActive,
        setBookingActive,
        settingsFormActive,
        setSettingsFormActive,
        settingsForm,
        setSettingsForm,
        event,
        setEvent,
        user,
        setUser,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth redirectTo="/login">
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/booking/:id"
            element={
              <RequireAuth redirectTo="/login">
                <Booking />
              </RequireAuth>
            }
          />
          <Route
            path="/settings"
            element={
              <RequireAuth redirectTo="/login">
                <Settings />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

function RequireAuth({ children, redirectTo }) {
  const { isAuthenticated } = useContext(AuthContext);
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

export default App;
