import axios from "axios";
axios.defaults.baseURL =
  "https://dpotrmvyo8.execute-api.af-south-1.amazonaws.com/dev/robot_sohotst";

export async function getBookings(startDate, endDate) {
  return await axios
    .get(`/bookings`, {
      params: {
        startdate: startDate,
        enddate: endDate,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export async function getBooking(bookingId) {
  return await axios.get(`/bookings/${bookingId}`).then((res) => {
    return res.data;
  });
}

export async function getClients() {
  return await axios.get(`/client`).then((res) => {
    return res.data;
  });
}

export async function getServices() {
  return await axios.get(`/service`).then((res) => {
    return res.data;
  });
}

export async function getPrices() {
  return await axios.get(`/prices`).then((res) => {
    return res.data;
  });
}

export async function getLocations() {
  return await axios.get(`/location`).then((res) => {
    return res.data;
  });
}

export async function getVehicles() {
  return await axios.get(`/vehicle`).then((res) => {
    return res.data;
  });
}

export async function getPaymentTypes() {
  return await axios.get(`/payment`).then((res) => {
    return res.data;
  });
}

export async function getUsers() {
  return await axios.get(`/user`).then((res) => {
    return res.data;
  });
}

export async function auth(username, password) {
  const options = {
    auth: {
      username: username,
      password: password,
    },
  };
  return await axios
    .get(`/auth`, options)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
