function NotFound() {
  return (
    <div className="notfound">
      <h1>404 Page Not Found</h1>
      <p>We're sorry, the page you requested could not be found.</p>
    </div>
  );
}

export default NotFound;
